// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/
jQuery(function () {
  jQuery("a, button").on("click", function (event) {
    if (jQuery(this).is(".disabled")) {
      event.preventDefault();
      return false;
    }
  });

  jQuery('input[name*="remove_file"], .image input[name*="_destroy"]').on("change", function (event) {
    let that = jQuery(this);
    if (that.is(":checked")) {
      that.closest(".image").find("img").css("opacity", "0.5");
      return that.closest(".file").find("input").prop("readonly", true);
    } else {
      that.closest(".image").find("img").css("opacity", "1");
      return that.closest(".file").find("input").prop("readonly", false);
    }
  });

  // zmena suboru
  jQuery('a[class*="change_nested_fields"]').on("click", function (event) {
    let that = jQuery(this);

    if (!that.is(".disabled")) {
      let controls = that.parents(".input-group");
      if (that.is(".active")) {
        controls.find("img").show();
        controls.find('input[type="file"]').replaceWith(controls.find('input[type="file"]').clone());
        controls.find('input[type="file"]').val("").hide();
        that.removeClass("active");
        return that.nextAll('a[class*="remove_nested_fields"]').removeClass("disabled");
      } else {
        controls.find("img").hide();
        controls.find('input[type="file"]').show();
        that.addClass("active");
        return that.nextAll('a[class*="remove_nested_fields"]').addClass("disabled");
      }
    }
  });

  // mazanie suboru
  jQuery("form").on("nested:fieldRemoved", function (event) {
    let { field } = event;
    if (event.field.find('input[name*="document_id"]').val() || event.field.find('input[name*="_attributes][document_id]"]').length) {
      // ak nie je nove, tak elementom nastavime len readonly
      if (field.prevObject.is(".active")) {
        field.show();
        field.prevObject.prev("input[type=hidden]").val(0);
        field.prevObject.prevAll("a").removeClass("disabled");
        field.prevObject.removeClass("active");
        field.find("input, select, textarea").prop("readonly", false);
        return field.find("img").css("opacity", "1");
      } else {
        field.show();
        field.prevObject.addClass("active");
        field.prevObject.prevAll("a").addClass("disabled");
        field.find("input, select, textarea").prop("readonly", true);
        return field.find("img").css("opacity", "0.5");
      }
    }
  });

  jQuery('img + input[type="file"]').hide();

  const inputElement = document.querySelector('input.filepond[type="file"]');

  FilePond.registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);
  const pond = FilePond.create(inputElement, {
    server: {
      headers: {
        "X-CSRF-Token": jQuery('meta[name="csrf-token"]').attr("content"),
      },
    },
  });
});
