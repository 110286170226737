/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)

require("./application_style.scss");

window.jQuery = window.$ = require("jquery");
require("jquery-migrate");
window.I18n = require("i18n-js");
require("./i18n-js-translations");
require("jquery-ujs");
require("jquery-ui/ui/widgets/sortable");
window.Modernizr = require("./modernizr-custom");
require("foundation-sites/js/foundation/foundation");
require("foundation-sites/js/foundation/foundation.topbar");
require("foundation-sites/js/foundation/foundation.tooltip");
require("foundation-sites/js/foundation/foundation.reveal");
require("foundation-sites/js/foundation/foundation.tab");
require("foundation-sites/js/foundation/foundation.accordion");
require("foundation-sites/js/foundation/foundation.dropdown");
require("./vendor/jquery.dataTables.min");
// require("./vendor/datatables.foundation.min.js");
// require("./vendor/datatables.buttons.min");
// require("./vendor/datatables.buttons.foundation.min");
// require("./vendor/datatables.buttons.html5.min");
// require("./vendor/datatables.buttons.colVis.min");
require("./vendor/datatables.accent-neutralise");
require("./vendor/datatables.diacritics-neutralise");
require("./vendor/jquery_nested_form");
require("./vendor/jquery.form.min");
require("./vendor/jquery.flot.min");
require("./vendor/jquery.flot.pie.min");
require("./vendor/selectize.min");
require("./vendor/highcharts");
window.FilePond = require("filepond");
window.FilePondPluginFileValidateType = require("filepond-plugin-file-validate-type");
window.FilePondPluginFileValidateSize = require("filepond-plugin-file-validate-size");
require("./dataTables_config");
require("./default");
require("./documents");
require("./operation_times");
require("./sortable");
require("./users");
require("./validation");
require("./widgets");
require("./external_resource");
require("./rails/ajax_events");
require("./rails/modal");
require("./wysiwyg");
require("./forms");
window.Noty = require("noty");

jQuery(document).ready(function () {
  jQuery(document).foundation();
});
