window.datatables = function (namespace) {
  if (namespace == null) {
    namespace = "";
  }
  let data_table_language = {
    sProcessing: I18n.t("datatables.processing"),
    sLengthMenu: I18n.t("datatables.length_menu"),
    sZeroRecords: I18n.t("datatables.zero_records"),
    sInfo: I18n.t("datatables.info"),
    sInfoEmpty: I18n.t("datatables.info_empty"),
    sInfoFiltered: I18n.t("datatables.info_filtered"),
    sInfoPostFix: "",
    sSearch: I18n.t("datatables.search"),
    sUrl: "",
    oPaginate: {
      sFirst: I18n.t("datatables.first"),
      sPrevious: I18n.t("datatables.previous"),
      sNext: I18n.t("datatables.next"),
      sLast: I18n.t("datatables.last"),
    },
  };

  let datatable = jQuery(namespace + " table.data").DataTable({
    dom: "Bfrtip",
    columnDefs: [{ sortable: false, searchable: false, targets: [-1] }],
    language: data_table_language,
    searching: true,
    search: {
      smart: true,
      regex: false,
      caseInsensitive: true,
    },
    ordering: true,
    paging: true,
    buttons: ["pageLength"],
    // buttons: ["pageLength", "colvis", "csvHtml5"],
  });

  jQuery(".dataTables_filter input").keyup(function () {
    datatable.search(jQuery.fn.DataTable.ext.type.search.string(this.value)).draw();
  });

  let datatable_mini = jQuery(namespace + " table.mini_data").DataTable({
    dom: "Bfrtip",
    paginate: false,
    filter: false,
    lengthChange: false,
    columnDefs: [{ sortable: false, searchable: false, targets: [-1] }],
    language: data_table_language,
    buttons: ["pageLength"],
    // buttons: ["pageLength", "colvis", "csvHtml5"],
  });
};
