jQuery(function() {
  jQuery(
    "table.sortable tbody, ul.sortable, ol.sortable, div.sortable"
  ).sortable({
    axis: "y",
    items: "> *:not(a)",
    handle: ".sorter",

    update(event) {
      // ajax
      if (jQuery(this).data("update-url")) {
        return jQuery.ajax({
          type: "PUT",
          url: jQuery(this).data("update-url") + ".js",
          data: jQuery(this).sortable("serialize"),
          dataType: "script"
        });
        // non ajax
      } else {
        return jQuery(this)
          .find('input[id$="position"]')
          .each((i, input_tag) => jQuery(input_tag).val(i + 1));
      }
    },
    helper(e, tr) {
      let $originals = tr.children();
      let $helper = tr.clone();
      $helper.children().each(function(index) {
        return jQuery(this).width($originals.eq(index).width());
      });

      return $helper;
    }
  });
});
