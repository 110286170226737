jQuery(function () {
  jQuery('button[name$="[load_data]"]').on("click", function (event) {
    const jQueryElement = jQuery(this);
    const resourceUrl = decodeURIComponent(jQueryElement.data("url"));
    const inputGroupElement = jQueryElement.closest(".input-group");
    const resourceIdElement = inputGroupElement.find('input[name$="[resource_id]"]');
    const resourceId = resourceIdElement.val().trim();

    if (resourceId !== "") {
      jQuery.get(resourceUrl.replace("{resource_id}", resourceId), function (data) {
        if (data === null) return;

        const formElement = jQueryElement.closest("form");
        formElement.find('input[name$="[name]"]').val(data.name);

        if (data.address) {
          formElement.find('input[name$="[contact_attributes][address_attributes][locality]"]').val(data.address.city);
          formElement.find('input[name$="[contact_attributes][address_attributes][street]"]').val(data.address.street);
          formElement.find('input[name$="[contact_attributes][address_attributes][postal_code]"]').val(data.address.postcode);
          formElement.find('input[name$="[contact_attributes][address_attributes][country]"]').val(data.address.country.name);
          formElement.find('input[name$="[contact_attributes][address_attributes][country]"]').blur();
        }

        if (data.contact) {
          formElement.find('input[name$="[contact_attributes][connection_attributes][website]"]').val(data.contact.www);
          formElement.find('input[name$="[contact_attributes][connection_attributes][email]"]').val(data.contact.email);
          formElement.find('input[name$="[contact_attributes][connection_attributes][phone]"]').val(data.contact.phone);
          formElement.find('input[name$="[contact_attributes][connection_attributes][facebook]"]').val(data.contact.facebook);
          formElement.find('input[name$="[contact_attributes][connection_attributes][twitter]"]').val(data.contact.twitter);
        }

        new Noty({
          text: "Source data loaded",
          timeout: 3000,
        }).show();
      });
    }
  });
});
