jQuery(function() {

  jQuery('#widget_used_for').on('change', function(event) {
    let that = jQuery(this);
    jQuery.get(`/widgets/groups_by_using/?used_for=${that.val()}`);
    return jQuery.get(`/widgets/block_positions_by_using/?used_for=${that.val()}`);
  });

  return jQuery('#widget_group').on('change', function(event) {
    let that = jQuery(this);
    let used_for = jQuery('#widget_used_for');

    return jQuery.get(`/widgets/entities_by_group/?used_for=${used_for.val()}&group=${that.val()}`);
  });
});
