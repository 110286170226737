// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
// You can use CoffeeScript in this file: http://jashkenas.github.com/coffee-script/

let operation_times_checkboxes = 'input[name*="whole_day"], input[name*="closed"]';
let nonstop_selector = 'input[name*="whole_day"][value="1"]';
let closed_selector = 'input[name*="closed"][value="1"]';
let start_hour_selector = 'select[name*="start_time(4i)"]';
let start_minute_selector = 'select[name*="start_time(5i)"]';
let end_hour_selector = 'select[name*="end_time(4i)"]';
let end_minute_selector = 'select[name*="end_time(5i)"]';
let start_selector = start_hour_selector + ", " + start_minute_selector;
let end_selector = end_hour_selector + ", " + end_minute_selector;
let day_wrapper_selector = ".day-wrapper";
let skip_periods_selector = 'input[name*="skip_periods"]';

class OperationTime {
  static initClass() {
    this.closed_input;
    this.nonstop_input;
    this.operation_time_start;
    this.operation_time_end;
  }

  constructor(wrapper) {
    this.wrapper = wrapper;
    this.closed_input = jQuery(this.wrapper).find(closed_selector);
    this.nonstop_input = jQuery(this.wrapper).find(nonstop_selector);
    this.operation_time_start = jQuery(this.wrapper).find(start_selector);
    this.operation_time_end = jQuery(this.wrapper).find(end_selector);
  }

  enable_operation_times() {
    this.operation_time_start.prop("disabled", false);
    this.operation_time_end.prop("disabled", false);
    this.operation_time_start.find("option").prop("disabled", false);
    this.operation_time_end.find("option").prop("disabled", false);
  }

  disable_operation_times() {
    this.operation_time_start.prop("disabled", true);
    this.operation_time_end.prop("disabled", true);
    this.operation_time_start.find("option").prop("disabled", true);
    this.operation_time_end.find("option").prop("disabled", true);
  }

  set_nonstop_times() {
    this.set_start_time("00", "00");
    this.set_end_time("24", "00");
  }

  enable_nonstop() {
    this.nonstop_input.prop("disabled", false);
  }

  disable_nonstop() {
    this.nonstop_input.prop("disabled", true);
  }

  disable_nonstop_others() {
    this.operation_time_start.find(':not(option[value="00"])').prop("disabled", true);
    this.operation_time_end.filter(end_hour_selector).find(':not(option[value="24"])').prop("disabled", true);
    this.operation_time_end.filter(end_minute_selector).find(':not(option[value="00"])').prop("disabled", true);
  }

  enable_closed() {
    this.closed_input.prop("disabled", false);
  }

  disable_closed() {
    this.closed_input.prop("disabled", true);
  }

  check_nonstop() {
    this.nonstop_input.prop("checked", true);
  }

  uncheck_nonstop() {
    this.nonstop_input.prop("checked", false);
  }

  check_closed() {
    this.closed_input.prop("checked", true);
  }

  uncheck_closed() {
    this.closed_input.prop("checked", false);
  }

  set_start_time(hour, minute) {
    this.hour = hour;
    this.minute = minute;
    this.operation_time_start.first().val(this.hour);
    this.operation_time_start.last().val(this.minute);
  }

  set_end_time(hour, minute) {
    this.hour = hour;
    this.minute = minute;
    this.operation_time_end.first().val(this.hour);
    this.operation_time_end.last().val(this.minute);
  }

  set_nonstop() {
    this.uncheck_closed();
    this.disable_closed();
    this.enable_nonstop();
    this.check_nonstop();
    this.set_nonstop_times();
    this.disable_nonstop_others();
  }

  set_closed() {
    this.uncheck_nonstop();
    this.disable_nonstop();
    this.enable_closed();
    this.check_closed();
    this.disable_operation_times();
  }

  unset_nonstop() {
    this.uncheck_nonstop();
    this.enable_closed();
    this.enable_operation_times();
  }

  unset_closed() {
    this.uncheck_closed();
    this.enable_nonstop();
    this.enable_operation_times();
  }

  click_nonstop() {
    this.nonstop_input.trigger("click");
  }

  click_closed() {
    return this.closed_input.trigger("click");
  }

  status() {
    let state =
      this.operation_time_start[0].value +
      ":" +
      this.operation_time_start[1].value +
      " - " +
      this.operation_time_end[0].value +
      ":" +
      this.operation_time_end[1].value;
    if (this.closed_input.prop("checked")) {
      state = I18n.t("activerecord.attributes.operation_times.closed");
    }
    if (this.nonstop_input.prop("checked")) {
      state = I18n.t("activerecord.attributes.operation_times.nonstop");
    }
    return state;
  }
}
OperationTime.initClass();

let toggle_hours = function (that) {
  let parent = that.closest(".day-wrapper");

  let operation_time = new OperationTime(parent);

  // hide
  if (that.is(":checked")) {
    if (that.is('[name*="whole_day"]')) {
      return operation_time.set_nonstop();
    } else if (that.is('[name*="closed"]')) {
      return operation_time.set_closed();
    }
    // show
  } else {
    if (that.is('[name*="whole_day"]')) {
      return operation_time.unset_nonstop();
    } else if (that.is('[name*="closed"]')) {
      return operation_time.unset_closed();
    }
  }
};

let init_operation_times = function () {
  jQuery(".operation_times_use_for_all").on("click", function (event) {
    let that = jQuery(this);
    let parent = that.closest(day_wrapper_selector);
    let wrapper = that.closest(".control-group");
    let first_operation_time = new OperationTime(parent);

    return wrapper.find(".day-wrapper:not(:first)").each(function (index, day_wrapper) {
      let operation_time = new OperationTime(day_wrapper);

      if (!first_operation_time.nonstop_input.is(":checked") || !first_operation_time.closed_input.is(":checked")) {
        operation_time.unset_closed();
        operation_time.unset_nonstop();
      }

      if (!first_operation_time.nonstop_input.is(":checked") && !first_operation_time.closed_input.is(":checked")) {
        operation_time.set_start_time(
          first_operation_time.operation_time_start.first().val(),
          first_operation_time.operation_time_start.last().val()
        );
        operation_time.set_end_time(
          first_operation_time.operation_time_end.first().val(),
          first_operation_time.operation_time_end.last().val()
        );
      }

      if (first_operation_time.nonstop_input.is(":checked")) {
        operation_time.unset_closed();
        operation_time.click_nonstop();
      }

      if (first_operation_time.closed_input.is(":checked")) {
        operation_time.unset_nonstop();
        return operation_time.click_closed();
      }
    });
  });

  jQuery(end_hour_selector).on("change", function (event) {
    let that = jQuery(this);
    let parent = that.closest(".day-wrapper");

    if (that.val() === "24") {
      return parent.find(end_minute_selector).val("00");
    }
  });

  jQuery(operation_times_checkboxes).on("change", function (event) {
    let that = jQuery(this);
    toggle_hours(that);
  });

  jQuery(operation_times_checkboxes).each(function (i, checkboxes) {
    let that = jQuery(checkboxes).filter('[type="checkbox"]').filter(":checked");
    if (that.length) {
      toggle_hours(that);
    }
  });

  jQuery(skip_periods_selector).on("change", function (event) {
    let that = jQuery(this);
    let wrapper = that.closest(".control-group").find(".days-wrapper, .period-wrapper");
    let wholePeriod = that.closest(".control-group").find(".whole-period");

    // hide
    if (that.is(":checked")) {
      wrapper.hide();
      wholePeriod.show();
      // show
    } else {
      wrapper.show();
      wholePeriod.hide();
    }
  });

  return jQuery(skip_periods_selector).each(function (index, skip_period_selector) {
    if (jQuery(skip_period_selector).is(":checked")) {
      jQuery(skip_period_selector).closest(".control-group").find(".days-wrapper, .period-wrapper").hide();
      jQuery(skip_period_selector).closest(".control-group").find(".whole-period").show();
    } else {
      jQuery(skip_period_selector).closest(".control-group").find(".whole-period").hide();
    }
  });
};

jQuery(() => init_operation_times());

jQuery(document).on("nested:fieldAdded:operation_times", (event) => init_operation_times());
