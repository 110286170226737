// import Quill from "quill";
import tinymce from "tinymce";
import "tinymce/icons/default/icons.min";
import "tinymce/themes/silver";
import "tinymce/plugins/code";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/lists";
import "tinymce/plugins/table";

jQuery(document).ready(function () {
  tinymce.init({
    selector: "textarea.wysiwyg",
    plugins: "code fullscreen lists table",
    toolbar: "undo redo | formatselect | bold italic strikethrough | subscript superscript | numlist bullist | table | code",
    menubar: false,
    block_formats: "Paragraph=p; Header 2=h2; Header 3=h3;",
    table_toolbar:
      "bold italic tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
    skin: false,
    height: 600,
    entity_encoding: "raw",
  });

  tinymce.init({
    selector: "textarea.wysiwyg-simple",
    plugins: "code fullscreen lists table",
    toolbar: "undo redo | formatselect | bold italic strikethrough | subscript superscript | numlist bullist | code",
    menubar: false,
    block_formats: "Paragraph=p; Header 2=h2; Header 3=h3;",
    skin: false,
    height: 600,
    entity_encoding: "raw",
  });
});
