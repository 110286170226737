jQuery(function() {
  window.modal_window = jQuery("#modal-window");
  window.modal_window.title = modal_window.find(".modal-title");
  window.modal_window.content = modal_window.find(".modal-body");

  jQuery(window.modal_window).on("closed.fndtn.reveal", function(event) {
    window.modal_window.title.html("");
    return window.modal_window.content.html("");
  });

  setTimeout(function() {
    if (window.location.hash) {
      let { hash } = window.location;
      return jQuery(`.tabs a[href='${hash}']`).trigger("click");
    } else {
      return jQuery(".tabs a:first").trigger("click");
    }
  });

  jQuery(".tab-pager a").on("click", function(event) {
    let id = jQuery(this).attr("href");
    return jQuery(`.tabs a[href='${id}']`).trigger("click");
  });

  datatables();

  jQuery('button[data-action="reorder"], a[data-action="reorder"]').on(
    "click",
    function(event) {
      let that = jQuery(this);
      if (that.hasClass("active")) {
        that.removeClass("active");
        return jQuery(".reorder").addClass("hide");
      } else {
        that.addClass("active");
        return jQuery(".reorder").removeClass("hide");
      }
    }
  );

  // jQuery('button[data-action], a[data-action]').on 'click', (event) ->
  //   that = jQuery(this)
  //   action = that.data('action')

  jQuery("select[multiple]").selectize({
    plugins: ["restore_on_backspace", "remove_button", "drag_drop"],
    delimiter: ","
  });

  jQuery("select.selectize").selectize({
    sortField: "text"
  });
});
