// Expanded version of jQuery.rails.allowAction from jquery_ujs.js
//
// Falls back to plain window.confirm() for links/buttons with just a simple 'confirm' key...
//   = link_to 'Delete', foo_path(foo), method: :delete, confirm: 'Are you sure?'
//
// Uses a nice Zurb Foundation modal window for links/buttons with HTML5 data set...
//   = link_to 'Delete', foo_path(foo), method: :delete, data: {
//       confirm: {
//         title: 'You might want to think twice about this!',
//         body: 'If you click "Simon Says Delete", there will be no takebacks!',
//         password: 'YESREALLY',
//         ok: 'Simon Says Delete'
//       }
//     }
//
// Options:
//   title        Text for title bar of modal popup
//   body         Warning inside main content area of popup
//   password     If set, will require the user to type out this string to enable the action
//   prompt       Format string for password prompt (password inserted in place of %s)
//   ok           Label for the button that does the delete/destroy/etc.
//   ok_class     CSS class for the button that does the delete/destroy/etc.
//   cancel       Label for the button that cancels out of the action
//   cancel_class CSS class for the button that cancels out of the action

let localization_defaults = {
  title: I18n.t('helpers.links.confirm'),
  body: I18n.t('helpers.links.confirm_destroy'),
  password: false,
  prompt: 'Type <i>%s</i> to continue:',
  ok: I18n.t('helpers.links.destroy'),
  ok_class: 'button alert alert confirm',
  cancel: I18n.t('helpers.links.cancel'),
  cancel_class: 'button cancel'
};

let reveal_confirm = function(element) {

  let password;
  let confirm_localization = jQuery.extend({},
    localization_defaults, window.confirm_localization);

  let confirm = element.data('confirm');
  if (!confirm) { return true; }
  // if typeof confirm == 'string'
  //   return window.confirm confirm

  let modal = $(`\
<div class='reveal-modal medium' data-reveal>
  <h2 class='header'></h2>
  <p class='warning'></p>
  <div class='footer'>
    <a class='cancel-button ${confirm.cancel_class || confirm_localization['cancel_class']}'></a>
  </div>
  <a class="close-reveal-modal">&#215;</a>
</div>\
`
  );

  modal
    .find('.header')
    .html(confirm.title || confirm_localization['title']);
  modal
    .find('.warning')
    .html(confirm.body || confirm_localization['body']);
  modal
    .find('.cancel-button')
    .html(confirm.cancel || confirm_localization['cancel']);

  let confirm_button = element.is('a') ? element.clone() : jQuery('<a/>');
  confirm_button
    .removeAttr('class')
    .removeAttr('data-confirm')
    .addClass(confirm.ok_class || confirm_localization['ok_class'])
    .html(confirm.ok || confirm_localization['ok']);

  if (element.is('form') || element.is(':input')) {
    confirm_button.on('click', () =>
      element
        .closest('form')
        .removeAttr('data-confirm')
        .submit()
    );
  }

  modal
    .find('.button.cancel')
    .on('click', e => modal.foundation('reveal', 'close'));
  modal
    .find('.footer')
    .append(confirm_button);

  if (password = confirm.password || confirm_localization['password']) {
    let confirm_label =
      (confirm.prompt || confirm_localization['prompt'])
        .replace('%s', password);
    let confirm_html = `\
<label>${confirm_label}</label>
<input class='confirm-password' type='text' />\
`;
    modal
      .find('.warning')
      .after(jQuery(confirm_html));
    modal
      .find('.confirm-password')
      .on('keyup', function(e) {
        return confirm_button.toggleClass('disabled', jQuery(this).val() !== password);
    });
    confirm_button
      .addClass('disabled')
      .on('click', function(e) {
        if (jQuery(this).hasClass('disabled')) { return false; }
    });
  }

  modal
    .appendTo(jQuery('body'))
    .foundation()
    .foundation('reveal', 'open')
    .on('closed.fndtn.reveal', e => modal.remove());

  return false;
};

if (jQuery.rails) {

  jQuery.rails.allowAction = reveal_confirm;

} else {

  let confirm_handler = function(e) {
    let proceed = reveal_confirm(jQuery(this));
    if (!proceed) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
    return proceed;
  };

  jQuery(document).on('click', 'a[data-confirm], :input[data-confirm]', confirm_handler);
  jQuery(document).on('submit', 'form[data-confirm]', confirm_handler);
}
