jQuery(function() {

  let fields = jQuery('#access_user_password, #access_user_password_confirmation');
  jQuery('#access_user_create_password').on('click', function(event) {
    let that = jQuery(this);

    if (that.is(':checked')) {
      fields.parents('.form-block').hide();
      return fields.removeProp('required');
    } else {
      fields.parents('.form-block').show();
      return fields.prop('required', true);
    }
  });

  if (jQuery('#access_user_create_password').is(':checked')) {
    fields.parents('.form-block').hide();
    return fields.removeProp('required');
  }
});
