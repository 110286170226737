jQuery(function() {

  jQuery(window).on('ajax:send', function(event) {
    if (jQuery(event['target']).data('reveal-id') === 'modal-window') {
      modal_window.title.html(I18n.t('helpers.links.loading'));
      return modal_window.content.html('<div class="slideshow-wrapper"><div class="preloader"><p>&nbsp;</p></div></div>');
    }
  });
      //modal_window.foundation('reveal', 'open')

  // re-enable disable_with on back button event
  return jQuery(window).on('unload', () => jQuery.rails.enableFormElements(jQuery(jQuery.rails.formSubmitSelector)));
});
